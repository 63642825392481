<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <div>
                          <b-table-simple striped bordered small>
                              <b-tr>
                                <b-th>{{ $t('barc_config.application_name') }}</b-th>
                                <b-td style="width:70%">{{ (this.$i18n.locale=='bn')? ssPolictSetupData.application_name_bn : ssPolictSetupData.application_name }}</b-td>
                              </b-tr>
                          </b-table-simple>
                          <b-row>
                                <b-col lg="12" sm="12" class="text-center mb-4">
                                    <h5 class='complain-title'>{{ $t('globalTrans.details') }}</h5>
                                </b-col>
                          </b-row>
                          <b-table bordered hover :items="ssPolictSetupData.details" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                <template v-slot:cell(index)="data">
                                  {{ $n(data.index + 1) }}
                                </template>
                                <template v-slot:cell(max_amount_taka)="data">
                                  {{ $n(data.item.max_amount_taka) }}
                                </template>
                                <template v-slot:cell(max_amount_percent)="data">
                                  {{ $n(data.item.max_amount_percent) }}
                                </template>
                          </b-table>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
export default {
  name: 'FormLayout',
  props: ['item'],
  created () {
    this.ssPolictSetupData = this.item
  },
  data () {
    return {
      ssPolictSetupData: [],
      slOffset: 1
    }
  },
  computed: {
    localeLang () {
      return this.$i18n.locale
    },
    listData () {
      const objectData = this.item
      return objectData.filter(data => data.id === this.item.id)
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('barc_config.application_checklist_heading'), class: 'text-left' },
          { label: this.$t('barc_config.required_document_description'), class: 'text-left' },
          { label: this.$t('dae_config.sorting_order'), class: 'text-left' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'checklist_heading_bn' },
          { key: 'description_bn' },
          { key: 'sorting_order' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'checklist_heading' },
          { key: 'description' },
          { key: 'sorting_order' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  methods: {
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
