<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <b-col lg="8" sm="12">
                          <ValidationProvider name="Application Name" vid="application_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="3"
                              label-for="application_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('barc_config.application_name') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                              plain
                              v-model="appCriteriaSetup.application_id"
                              :options="applicationNameList"
                              id="application_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <!-- <b-row>
                          <b-col lg="12" sm="12" class="mb-2" style="margin:10px 0;">
                              <h5 style="font-weight:bold">
                                  {{ $t('barc_config.item_add_for_budget_policy') }}
                              </h5>
                          </b-col>
                        </b-row> -->
                        <b-row style="border-bottom:1px solid #ddd; margin-bottom:10px">
                          <b-col lg="2">
                              <p class="text-dark">{{ $t('barc_config.application_checklist_heading_en') }} <span class="text-danger">*</span></p>
                          </b-col>
                          <b-col lg="2">
                              <p class="text-dark">{{ $t('barc_config.application_checklist_heading_bn') }} <span class="text-danger">*</span></p>
                          </b-col>
                          <b-col lg="2">
                              <p class="text-dark">{{ $t('barc_config.required_document_description_en') }} <span class="text-danger">*</span></p>
                          </b-col>
                          <b-col lg="2">
                              <p class="text-dark">{{ $t('barc_config.required_document_description_bn') }} <span class="text-danger">*</span></p>
                          </b-col>
                          <b-col lg="2">
                              <p class="text-dark">{{ $t('dae_config.sorting_order') }} <span class="text-danger">*</span></p>
                          </b-col>
                          <b-col lg="1">
                              <p class="text-dark">{{ $t('globalTrans.action') }}</p>
                          </b-col>
                        </b-row>
                        <b-row v-for="(wordn,index) in appCriteriaSetup.details" :key="index">
                          <b-col xl="2" lg="2" sm="8">
                            <ValidationProvider name="Application Checklist Heading (En)" vid="checklist_heading" rules="required">
                              <b-form-group
                              class="row"
                              label-for="checklist_heading"
                              slot-scope="{ valid, errors }"
                              >
                              <b-form-input
                                :id="index.toString()"
                                v-model="wordn.checklist_heading"
                                @change="getPercentage(index)"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col xl="2" lg="2" sm="8">
                            <ValidationProvider name="Application Checklist Heading (Bn)" vid="checklist_heading_bn" rules="required">
                              <b-form-group
                              class="row"
                              label-for="checklist_heading_bn"
                              slot-scope="{ valid, errors }"
                              >
                              <b-form-input
                                ml="3"
                                :id="index.toString()"
                                v-model="wordn.checklist_heading_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col xl="2" lg="2" sm="8">
                              <ValidationProvider name="Required Document Description (En)" vid="description" rules="required">
                                  <b-form-group
                                  class="row"
                                  label-for="description"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <b-form-input
                                      ml="3"
                                      :id="index.toString()"
                                      v-model="wordn.description"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col xl="2" lg="2" sm="8">
                            <ValidationProvider name="Required Document Description (Bn)" vid="description_bn" rules="required">
                              <b-form-group
                              class="row"
                              label-for="description_bn"
                              slot-scope="{ valid, errors }"
                              >
                              <b-form-input
                                  ml="3"
                                  :id="index.toString()"
                                  v-model="wordn.description_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col xl="2" lg="2" sm="8">
                              <ValidationProvider name="Sorting Order" vid="sorting_order" rules="required">
                                  <b-form-group
                                  class="row"
                                  label-for="sorting_order"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <b-form-input
                                    type="number"
                                    ml="3"
                                    :id="index.toString()"
                                    v-model="wordn.sorting_order"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col xl="1" lg="1" sm="4">
                              <b-button v-show="index == appCriteriaSetup.details.length-1" variant=" iq-bg-success" size="sm" @click="add()"><i class="ri-add-line m-0"></i></b-button>
                              <b-button v-show="index || ( !index && appCriteriaSetup.details.length > 1)" variant=" iq-bg-danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { appCriteriaSetupStore, appCriteriaSetupUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getAppCriteriaSetupData()
      this.appCriteriaSetup = tmp
      const detailsInfo = tmp.details.map(item => {
        return {
          checklist_heading: item.checklist_heading,
          checklist_heading_bn: item.checklist_heading_bn,
          description: item.description,
          description_bn: item.description_bn,
          sorting_order: item.sorting_order
        }
      })
      this.appCriteriaSetup.details = detailsInfo
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      totalPost: '0',
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      appCriteriaSetup: {
        application_id: '0',
        details: [
          {
            checklist_heading: '',
            checklist_heading_bn: '',
            description: '',
            description_bn: '',
            sorting_order: ''
          }
        ]
      }
    }
  },
  computed: {
    applicationNameList: function () {
      const objectData = this.$store.state.incentiveGrant.commonObj.applicationList
      return objectData.map(obj => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en }
          }
      })
    }
  },
  watch: {
  },
  methods: {
    getAppCriteriaSetupData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.id) {
          result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${appCriteriaSetupUpdate}/${this.id}`, this.appCriteriaSetup)
      } else {
          result = await RestApi.postData(incentiveGrantServiceBaseUrl, appCriteriaSetupStore, this.appCriteriaSetup)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
          this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
          this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
          })

          this.$bvModal.hide('modal-4')
      } else {
          this.$refs.form.setErrors(result.errors)
      }
    },
    add () {
      const tampbpSetup = {
        checklist_heading: '',
        checklist_heading_bn: '',
        description: '',
        description_bn: '',
        sorting_order: ''
      }
      const key1 = parseInt(this.appCriteriaSetup.details.length - 1)
      const item = this.appCriteriaSetup.details[key1]
      let isEmpty = true
      Object.keys(item).map(key => {
          if (item[key] === '' || item[key] === 0) {
              isEmpty = false
          }
      })
      if (isEmpty === true) {
          this.appCriteriaSetup.details.push(tampbpSetup)
      }
    },
    remove (key) {
        this.appCriteriaSetup.details.splice(key, 1)
    }
  }
}
</script>
